import { fetchGet, fetchPost } from "../../router/https"
// 签到首页
function collection(data){
    return fetchGet('/mallapp/signrecord/user',data)
}
// 签到
function signClick(data){
    return fetchPost('/mallapp/signrecord/user',data)
}
export {
    collection,
    signClick
}