c<template>
  <div>
    <div>
      <van-nav-bar
        title="每日签到"
        left-arrow
        :fixed="true"
        @click-left="Closeback"
      >
      </van-nav-bar>
    </div>
    <!--签到主体-->
    <div style="overflow: scroll">
      <!---->
      <div class="sigin-div">
        <!-- 签到 -->
        <div @click="onSingin">
          <div class="radis">{{ singinStatu }}</div>
        </div>
      </div>
      <!-- 签到日期 -->
      <div class="daycss">
        <div>
          <span style="margin-left: 20px; font-size: 16px; font-weight: bold"
            >已经连续签到<span style="color: #f52556"
              >{{ dayNum }}天</span
            ></span
          >
        </div>
        <div class="sign-boxes">
          <div
            class="sign-box"
            :style="{ background: dayNum >= index + 1 ? '#F52556' : '' }"
            v-for="(item, index) in signData"
            :key="index"
          >
            <span
              :style="{ color: dayNum >= index + 1 ? '#fff' : '' }"
              class="day"
              >第{{ item.sort }}天</span
            >
            <img src="../../assets/组 95@2x.png" />
            <span
              :style="{ color: dayNum >= index + 1 ? '#fff' : '' }"
              class="day-num"
              >+{{ item.posts }}积分</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { collection, signClick } from "@/api/sign2";

export default {
  data() {
    return {
      singinStatu: "未签到",
      singinInfo: {},
      nowWeek: "",
      nowDate: "",
      continuDays: "",
      deviceType1: "IMEI",
      deviceValue1: "1",
      classStatus: "singinWord",
      session: "",
      bonuspoint: [],
      typeValue: "0",
      dayNum: 1,
      updateTime: null,
      signData: [
        {
          day: "1",
          num: 1,
        },
        {
          day: "2",
          num: 2,
        },
        {
          day: "3",
          num: 3,
        },
        {
          day: "4",
          num: 4,
        },
        {
          day: "5",
          num: 5,
        },
        {
          day: "6",
          num: 6,
        },
        {
          day: "7",
          num: 7,
        },
      ],
    };
  },
  created() {
    this.$store.commit("setSession", this.$route.query.session)
    this.setObj();
    this.setNowTimes()
  },
  mounted() {
    if (this.$route.query.deviceValue != null) {
      this.deviceValue1 = this.$route.query.deviceValue;
    }
    if (this.$route.query.deviceType != null) {
      this.deviceType1 = this.$route.query.deviceType;
    }
    if (this.$route.query.session != null) {
      this.session = this.$route.query.session;
      this.$store.commit("setSession", this.session);
    }
    //获取app传递过来的token
    if (this.$route.query.token != null && this.$route.query.token != "") {
      this.$store.commit("setToken", this.$route.query.token);
    }
    //empower
    if (this.$route.query.empower != null && this.$route.query.empower != "") {
      this.$store.commit("setEmpower", this.$route.query.empower);
    }
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);
  },
 
  methods: {
      // rem
    setRem() {
      let screenWidth = document.documentElement.getBoundingClientRect().width;
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
     // 获取当前时间
  setNowTimes() {
    let myDate = new Date();
    // console.log(myDate)
    let wk = myDate.getDay();
    let yy = String(myDate.getFullYear());
    let mm = myDate.getMonth() + 1;
    let dd = String(
      myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate()
    );
    let hou = String(
      myDate.getHours() < 10 ? "0" + myDate.getHours() : myDate.getHours()
    );
    let min = String(
      myDate.getMinutes() < 10 ? "0" + myDate.getMinutes() : myDate.getMinutes()
    );
    let sec = String(
      myDate.getSeconds() < 10 ? "0" + myDate.getSeconds() : myDate.getSeconds()
    );
    let weeks = [
      "星期日",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六",
    ];
    let week = weeks[wk];
    if(mm - 0 < 10) {
      mm = '0' + mm
    }
    this.nowDate = yy + "-" + mm + "-" + dd;
    this.nowTime = hou + ":" + min + ":" + sec;
    this.nowWeek = week;
  },
    //签到首页
    setObj() {
      let id = this.$route.query.id || "1451013074176708609";
      collection({ id: id }).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          this.signData = res.data.data.signConfigList
          this.dayNum = res.data.data.signRecord.continuDays;
          this.updateTime = res.data.data.signRecord.updateTime;
          if (this.updateTime) {
            this.updateTime = this.updateTime.substr(0, 10);
            // console.log(this.updateTime, "updateTime");
            // 获取当前时间
            if (this.updateTime == this.nowDate) {
              this.singinStatu = "已签到"
            } else {
              this.singinStatu = "签到"
            }
          } else {
            this.singinStatu = "签到"
          }
        }
      });
    },
    //签到操作
    onSingin() {
      if(this.singinStatu == "已签到") {
        console.log('签到过了');
        return false
      } else {
          signClick().then((res) => {
            if (res.data.code === 0) {
              this.setObj();
              console.log(res);
            }
          });
      }
     
    },
    Closeback() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.sigin-div {
  background: url(../../assets/beijing2.png) 100% no-repeat;
  height: 7.8947rem;
  background-size: 100%;
  justify-content: flex-end;
  /*position: fixed;*/
  width: 100%;
}
.like {
  background: url(/img/编组6.png) 100% repeat;
  /*position: fixed;*/
  width: 100%;
  height: 0.7895rem;
  margin-top: 0.5263rem;
  /* margin-top: 360px;*/
}
.goods-like {
  /*position: fixed;*/
  width: 100%;
  position: absolute;
  padding-bottom: 0.5263rem;
  /*margin-top: 400px;*/
}
.radis {
  /*margin-top: 100px;*/
  margin-top: 2.3684rem;
  position: absolute;
  width: 2.6316rem;
  height: 2.6316rem;
  border-radius: 1.3158rem;
  line-height: 2.6316rem;
  text-align: center;
  margin-left: calc(50% - 1.3158rem);
  font-size: 0.5263rem;
  font-weight: bold;
  background-color: #fbe9c2;
}
.daycss {
  /*position:fixed;*/
  /* margin-top:270px;*/
  margin-top: -1.9737rem;
  margin-left: 3%;
  margin-right: 3%;
  background-color: white;
  border-radius: 0.1842rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  width: 94%;
  height: 6.1842rem;
}
.sign-boxes {
  margin-top: 0.1316rem;
  display: flex;
  flex-wrap: wrap;
  .sign-box {
    margin-bottom: 0.2632rem;
    margin-left: 0.1842rem;
    width: 2.1053rem;
    height: 2.3684rem;
    background: #fcfcfc;
    border-radius: 0.2632rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .day {
      font-size: 0.3947rem;
      margin-top: 0.0789rem;
    }
    .day-num {
      margin-top: 4px;
    }
    img {
      width: 0.7895rem;
      height: 0.7895rem;
      // margin-top: -10px;
    }
  }
}

.left,
.right {
  float: left;
  margin-top: 0.2632rem;
  margin-left: 1%;
  margin-left: 1%;
  width: 40%;
  height: 1.6053rem;
  border-radius: 0.1316rem;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 1.9988px 4.0014px 0px rgba(0, 0, 0, 0.2);
  height: 100%;
}
.right {
  float: right;
}
.word {
  left: 0.2895rem;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.3684rem;
  font-family: PingFangSC-Regular;
  line-height: 0.5263rem;
  text-align: left;
}
.info {
  overflow-wrap: break-word;
  color: rgb(204, 197, 197);
  font-size: 0.2632rem;
  font-family: PingFangSC-Regular;
  line-height: 0.3684rem;
  text-align: left;
}
.word4 {
  overflow-wrap: break-word;
  color: rgb(243, 132, 132);
  font-size: 0.4737rem;
  font-family: PingFangSC-Medium;
  line-height: 0.6579rem;
  text-align: left;
  margin-left: 1px;
}
.word10 {
  margin-left: 0.1316rem;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.2632rem;
  font-family: PingFangSC-Regular;
  line-height: 0.3684rem;
  text-align: center;
}
.divWeek {
  border-radius: 50%;
  text-align: center;
  background-color: #fbe9c2;
  width: 0.7895rem;
  height: 0.7895rem;
  text-align: center;
  display: flex;
  align-items: center;
}
.divWeek-singinOut {
  border-radius: 50%;
  text-align: center;
  background-color: #eeebe6;
  width: 0.7895rem;
  height: 0.7895rem;
  text-align: center;
  display: flex;
  align-items: center;
}
.divWeek-singin {
  border-radius: 50%;
  text-align: center;
  background-color: #ff6f00;
  width: 0.7895rem;
  height: 0.7895rem;
  text-align: center;
  display: flex;
  align-items: center;
}
.singinWord {
  color: rgba(255, 255, 255, 1);
  font-size: 0.2632rem;
  text-align: center;
  margin-left: 0.1842rem;
}
.col {
  width: 14%;
}
</style>
